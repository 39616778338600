<template>
  <el-tooltip
    v-model:visible="showMore"
    :disabled="!passport"
    placement="right"
  >
    <el-tag :type="type" size="small">
      <i v-if="icon" :class="icon" />
      <span
        :class="
          passport && passport.status === 'CLOSED' ? $style.strikeThrough : null
        "
        v-text="summary"
      />
    </el-tag>
    <template #content>
      <div :class="$style.moreInfo">
        <el-skeleton v-if="!fullPassport" animated :rows="1" />
        <template v-else>
          <div :class="$style.moreInfoInner">
            <div>
              <i v-if="icon" :class="[icon, $style.bigIcon]" />
              {{ getLongPassType }}
              <span
                :class="$style.bigNumber"
                v-text="`#${fullPassport.passportNumber}`"
              /><br />
              Gültigkeit: {{ passportValidity }}
              <el-icon><el-icon-refresh /></el-icon><br />
              Ausgestellt:
              <locale-date-time :date="fullPassport.issuedAt" /><br />
              Leistung: <passport-reason :passport="fullPassport" />
            </div>
            <div v-if="fullPassport.voucher" :class="$style.voucher">
              <el-icon :class="$style.bigIcon"><KaGraduationCap /></el-icon>
              {{ getShortVoucherType }}
              <span
                :class="$style.bigNumber"
                v-text="`#${fullPassport.voucher.voucherNumber}`"
              /><br />
              Gültigkeit: {{ voucherValidity }}
            </div>
          </div>
          <passport-status :passport="fullPassport" size="small" />
        </template>
      </div>
    </template>
  </el-tooltip>
</template>

<script>
import { Refresh as ElIconRefresh } from "@element-plus/icons";
import { formattedDate, formattedMonth } from "~/src/util/date";
import {
  getLongPassType,
  getPassportIcon,
  getPassportTagType,
  getShortVoucherType,
} from "~/src/util/passport";
import { PassportFragment } from "~/src/graphql/fragments";
import gql from "graphql-tag";
import PassportStatus from "~/src/components/passports/PassportStatus";
import LocaleDateTime from "~/src/components/common/i18n/LocaleDateTime";
import PassportReason from "~/src/components/passports/PassportReason";
import KaGraduationCap from "~/src/components/common/icons/KaGraduationCap.vue";

export default {
  name: "PassportInfo",
  components: {
    KaGraduationCap,
    PassportReason,
    LocaleDateTime,
    PassportStatus,
    ElIconRefresh,
  },
  props: {
    passport: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showMore: false,
      fullPassport: null,
    };
  },
  computed: {
    type() {
      const { passport } = this;
      return getPassportTagType(passport);
    },
    icon() {
      const { passport } = this;
      if (passport) {
        return getPassportIcon(passport);
      }
      return null;
    },
    summary() {
      const { passport } = this;
      if (passport) {
        return formattedMonth(passport.validUntil, true);
      }
      return "kein Pass";
    },
    getLongPassType() {
      const { fullPassport } = this;
      return fullPassport && getLongPassType(fullPassport.passportType);
    },
    passportValidity() {
      const { fullPassport } = this;
      if (!fullPassport) {
        return "";
      }
      if (fullPassport.closedAt) {
        return `${formattedDate(fullPassport.validFrom)}–${formattedDate(
          fullPassport.closedAt,
        )} (${formattedDate(fullPassport.validUntil)})`;
      }
      return `${formattedDate(fullPassport.validFrom)}–${formattedDate(
        fullPassport.validUntil,
      )}`;
    },
    getShortVoucherType() {
      const { fullPassport } = this;
      return (
        fullPassport &&
        fullPassport.voucher &&
        getShortVoucherType(fullPassport.voucher.voucherType)
      );
    },
    voucherValidity() {
      const { fullPassport } = this;
      if (!fullPassport || !fullPassport.voucher) {
        return "";
      }
      const voucher = fullPassport.voucher;
      if (voucher.closedAt) {
        return `${formattedDate(voucher.validFrom)}–${formattedDate(
          voucher.closedAt,
        )} (${formattedDate(voucher.validUntil)})`;
      }
      return `${formattedDate(voucher.validFrom)}–${formattedDate(
        voucher.validUntil,
      )}`;
    },
  },
  watch: {
    showMore(to) {
      if (to && !this.fullPassport) {
        this.$apollo.queries.fullPassport.refetch();
      }
    },
  },
  apollo: {
    fullPassport: {
      skip: (vm) => !vm.showMore,
      update: (data) => data.asEmployee.passport,
      query: gql`
        query AsEmployeeGetPassport($id: ID!) {
          asEmployee {
            passport(id: $id) {
              ...PassportFragment
            }
          }
        }
        ${PassportFragment}
      `,
      variables() {
        return {
          id: this.passport.id,
        };
      },
    },
  },
};
</script>

<style lang="module.css" module>
.strikeThrough {
  text-decoration: line-through
}
.moreInfo {
  min-width: 200px;
  display: flex;
}
.moreInfoInner {
  flex-grow: 1;
  margin-right: 10px;
}
.bigIcon {
  font-size: 150%;
  display: inline-block;
  margin-right: 3px;
}
.bigNumber {
  font-size: 150%;
}
.voucher {
  margin-top: 0.75rem;
}
</style>
