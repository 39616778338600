<template>
  <el-table-column width="64" prop="comments" align="center" :fixed="fixed">
    <template #header>
      <el-icon><el-icon-s-comment /></el-icon>
    </template>
    <template #default="{ row }">
      <comment-popover
        :commentable="subKey ? row[subKey] : row"
        @update="$emit('update', $event)"
      />
    </template>
  </el-table-column>
</template>

<script>
import { Comment as ElIconSComment } from "@element-plus/icons";
import CommentPopover from "~/src/components/comments/CommentPopover";

export default {
  name: "CommentColumn",
  components: {
    CommentPopover,
    ElIconSComment,
  },
  props: {
    subKey: {
      type: String,
      default: null,
    },
    fixed: {
      type: [Boolean, String],
      default: false,
    },
  },
  emits: ["update"],
};
</script>

<style lang="module.css" module>
.label {
  transform: scale(1.6);
}
</style>
