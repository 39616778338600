<template>
  <h2 :class="$style.heading">
    <span :class="$style.text"><slot /></span>
    <el-button-group v-if="$slots.actions" size="small" :class="$style.actions">
      <slot name="actions" />
    </el-button-group>
  </h2>
</template>

<script>
export default {
  name: "ListHeading",
};
</script>

<style lang="module.css" module>
.heading {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
.heading:not(:first-child) {
  margin-top: 2rem;
}
.text {

}
.actions {

}
</style>
