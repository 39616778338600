import KaKarlsruhe from "~/src/components/common/icons/KaKarlsruhe.vue";
import KaChild from "~/src/components/common/icons/KaChild.vue";
import KaTimerSand from "~/src/components/common/icons/KaTimerSand.vue";
import KaTimerSandDisabled from "~/src/components/common/icons/KaTimerSandDisabled.vue";
import KaTimerSandComplete from "~/src/components/common/icons/KaTimerSandComplete.vue";
import KaTimerOutline from "~/src/components/common/icons/KaTimerOutline.vue";
import KaGraduationCap from "~/src/components/common/icons/KaGraduationCap.vue";
import { Edit, Lock, Unlock } from "@element-plus/icons";

export const PASSPORT_STATUS_HUMAN = {
  VALID: "Gültig",
  CLOSED: "Zurückgezogen",
  NOT_VALID_ANYMORE: "Abgelaufen",
  NOT_YET_VALID: "Noch nicht gültig",
};

export const PASSPORT_TYPE_ICON = {
  KA_PASS: KaKarlsruhe,
  KI_PASS: KaChild,
};

export function getPassportIcon(passport) {
  if (passport && passport.passportType) {
    return PASSPORT_TYPE_ICON[passport.passportType];
  }
  return null;
}

export function getPassportTagType(passport) {
  if (passport) {
    switch (passport.status) {
      case "VALID":
        if (passport.renewable) {
          return "warning";
        }
        return "success";
      case "CLOSED":
        return "danger";
      case "NOT_VALID_ANYMORE":
        return "info";
      case "NOT_YET_VALID":
        return "info";
    }
  }
  return "info";
}

export const PASSPORT_STATUS_ICONS = {
  VALID: KaTimerSand,
  CLOSED: KaTimerSandDisabled,
  NOT_VALID_ANYMORE: KaTimerSandComplete,
  NOT_YET_VALID: KaTimerOutline,
};
export const PASSPORT_STATUS_STATUS_TYPE = {
  VALID: "success",
  CLOSED: "danger",
  NOT_VALID_ANYMORE: "primary",
  NOT_YET_VALID: "primary",
};

export function getPassportStatusIcon(passport) {
  if (passport) {
    return PASSPORT_STATUS_ICONS[passport.status];
  }
  return "";
}

export const INTS_TO_PASSPORT_TYPE = ["KA_PASS", "KI_PASS"];

export const PASSPORT_TYPE_HUMAN_SHORT = {
  KA_PASS: "KA Pass",
  KI_PASS: "KI Pass",
};

export function getShortPassType(passportType) {
  return PASSPORT_TYPE_HUMAN_SHORT[passportType] || passportType;
}
export const PASSPORT_TYPE_HUMAN_LONG = {
  KA_PASS: "Karlsruher Pass",
  KI_PASS: "Karlsruher Kinderpass",
};
export function getLongPassType(passportType) {
  return PASSPORT_TYPE_HUMAN_LONG[passportType] || passportType;
}

export function needsRunningPassportWarning(passport) {
  return passport.status === "VALID" && !passport.renewable;
}

export function getVoucherIcon(voucher) {
  switch (voucher.voucherType) {
    case "BI_GU":
    case "KI_GU":
      return KaGraduationCap;
  }
  return null;
}

export function getShortVoucherType(voucherType) {
  switch (voucherType) {
    case "BI_GU":
    case "KI_GU":
      return "BiGu";
  }
  return voucherType;
}

export const DIGITAL_PASSPORT_STATUS_HUMAN = {
  REDEEMABLE: "Einlösbar",
  OTHER_ISSUING: "Wird eingelöst",
  ISSUING_CLAIMED: "Wallet festgelegt",
  NOT_REDEEMABLE_ANYMORE: "Nicht mehr einlösbar",
  VALID: "Gültig",
  EXPIRED: "Abgelaufen",
  REVOKED: "Zurückgezogen",
};

export function getDigitalPassportTagType(digitalPassport) {
  if (digitalPassport && digitalPassport.status) {
    switch (digitalPassport.status) {
      case "VALID":
        return "success";
      case "CLOSED":
        return "danger";
      case "NOT_VALID_ANYMORE":
        return "info";
      case "NOT_YET_VALID":
        return "info";
    }
  }
  return "info";
}

const DIGITAL_PASSPORT_STATUS_ICONS = {
  REDEEMABLE: Unlock,
  OTHER_ISSUING: Edit,
  ISSUING_CLAIMED: Lock,
  NOT_REDEEMABLE_ANYMORE: KaTimerOutline,
  EXPIRED: KaTimerSandComplete,
  REVOKED: KaTimerSandDisabled,
  VALID: KaTimerSand,
};

export function getDigitalPassportStatusIcon(digitalPassport) {
  if (digitalPassport && digitalPassport.status) {
    return DIGITAL_PASSPORT_STATUS_ICONS[digitalPassport.status];
  }
  return "";
}
