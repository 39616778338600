import { computed, ref, watch } from "vue";

const KEY = "enabledFeatureFlags";

export const features = ref([]);
const initial = localStorage.getItem(KEY);
features.value = initial ? JSON.parse(initial) : [];
watch(features, () => {
  localStorage.setItem(KEY, JSON.stringify(features.value || []));
});

function has(feature) {
  return features.value.includes(feature);
}

export const hasSdika = computed(() => has("sdika"));
