// Cache implementation
import { InMemoryCache } from "@apollo/client/core";

export const cache = new InMemoryCache({
  typePolicies: {
    EmployeeQuery: {
      merge: false,
    },
  },
});

export function clearGraphQLCache() {
  console.log("clearGraphQLCache", cache);
  return cache.reset();
}
