<template>
  <el-container class="Layout" direction="vertical">
    <el-header class="AppHeader" :class="$style.header">
      <div class="AppHeader__left">
        <router-link
          :to="{ name: 'index' }"
          class="AppHeader__logo"
          aria-label="Zur Startseite"
        >
          <logo :height="52" />
        </router-link>
        <el-tooltip v-if="envNotProduction">
          <template #content>
            <div>
              Dies ist nicht die Produktivumgebung sondern die
              {{ env }}-Umgebung.<br />
              Hier darf probiert werden – ohne Angst haben zu müssen dass etwas
              kaputt geht.<br />
              <em>
                Achtung: Bitte keine wirklichen/produktiven Daten verwenden!
              </em>
              {{ $auth.loggingIn }}
            </div>
          </template>
          <el-tag :class="$style.envInfo" type="warning" effect="dark">
            {{ env }}
          </el-tag>
        </el-tooltip>
      </div>
      <el-menu
        ref="menu"
        background-color="transparent"
        class="AppHeader__right"
        :class="$style.menu"
        mode="horizontal"
        :ellipsis="true"
        router
        unique-opened
        :default-active="defaultActive"
      >
        <menu-link
          v-show="$auth.loggedIn.value"
          :to="{ name: 'index' }"
          only-exact
        >
          <el-icon><HomeFilled /></el-icon>
        </menu-link>

        <el-sub-menu
          v-show="$auth.hasRightPassportRead.value"
          index="passport-management-menu"
          popper-class="AppHeader__submenu"
        >
          <template #title>
            <el-icon><Ticket /></el-icon>
            Passausgabe
          </template>
          <menu-link :to="{ name: 'families' }">
            <el-icon><KaUsers /></el-icon>
            Familien/Personen
          </menu-link>
          <menu-link :to="{ name: 'applications' }">
            <el-icon><Tickets /></el-icon> Anträge
          </menu-link>
          <menu-link :to="{ name: 'passports' }">
            <el-icon><Ticket /></el-icon> Pässe
          </menu-link>
          <menu-link v-if="hasSdika" :to="{ name: 'digital-passports' }">
            <el-icon><Ticket /></el-icon> Digitale Pässe
          </menu-link>
        </el-sub-menu>

        <menu-link
          v-show="$auth.hasRightStatistics.value"
          :to="{ name: 'statistics' }"
        >
          <el-icon><KaChartPie /></el-icon>
          Statistik
        </menu-link>

        <el-sub-menu
          v-show="$auth.hasRightEmployeeManagement.value"
          index="management-menu"
          popper-class="AppHeader__submenu"
        >
          <template #title>
            <el-icon><SetUp /></el-icon>
            <span :class="$style.optionalMenuLabel">Erweitert</span>
          </template>
          <menu-link :to="{ name: 'perks' }">
            <el-icon><Discount /></el-icon> Extras
          </menu-link>
          <menu-link
            v-show="$auth.hasRightEmployeeManagement.value"
            :to="{ name: 'employees' }"
          >
            <el-icon><UserFilled /></el-icon> Mitarbeitende
          </menu-link>
        </el-sub-menu>

        <el-sub-menu
          v-show="$auth.loggedIn.value"
          index="user-menu"
          popper-class="AppHeader__submenu"
        >
          <template #title>
            <el-icon><Avatar /></el-icon>
            <span :class="$style.optionalMenuLabel">Ich</span>
          </template>
          <menu-link :to="{ name: 'profile' }">
            <el-icon><Setting /></el-icon>
            Mein Profil
          </menu-link>
          <el-menu-item class="has-link">
            <UseDark v-slot="{ isDark, toggleDark }">
              <button type="button" @click="toggleDark()">
                <el-icon><Sunny v-if="isDark" /><Moon v-else /></el-icon>
                Theme umschalten
              </button>
            </UseDark>
          </el-menu-item>
          <el-menu-item-group title="Logout">
            <el-menu-item class="has-link">
              <button type="button" @click="logout">
                <el-icon><SwitchButton /></el-icon>
                Abmelden
              </button>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-menu-item
          :class="$style.printerQueue"
          class="has-link"
          index=":printerQueue"
        >
          <button
            type="button"
            @click.prevent.stop="showPrinterQueue().catch(() => {})"
          >
            <el-icon><KaPrint /></el-icon>
            <span :class="$style.optionalMenuLabel">Direkt Drucken</span>
            <el-tag
              v-if="printerQueueLength > 0"
              type="primary"
              round
              effect="dark"
              size="small"
            >
              {{ printerQueueLength }}
            </el-tag>
          </button>
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-container direction="vertical" :class="$style.main">
      <router-view v-slot="{ Component }">
        <transition mode="out-in" name="trans-fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </el-container>
    <div class="Layout__footer-visual u-do-not-print">
      <img
        alt=""
        class="is-light"
        src="~images/unten.svg"
        width="5391"
        height="1460"
      />
      <img
        alt=""
        class="is-dark"
        src="~images/unten-dark.svg"
        width="5391"
        height="1460"
      />
    </div>
  </el-container>
</template>

<script>
import Logo from "~/src/components/common/layout/Logo";
import MenuLink from "~/src/components/common/layout/MenuLink";
import { RAILS_ENV } from "~/src/util/rails.js.erb";
import {
  PrinterQueueLength,
  showPrinterQueue,
  showPrinterSetup,
} from "~/src/util/web2print";
import Command from "~/src/components/mixins/Command";
import { hasSdika } from "~/src/util/feature-flags";
import KaUsers from "~/src/components/common/icons/KaUsers.vue";
import KaChartPie from "~/src/components/common/icons/KaChartPie.vue";
import KaPrint from "~/src/components/common/icons/KaPrint.vue";
import {
  Avatar,
  Discount,
  HomeFilled,
  Moon,
  Setting,
  SetUp,
  Sunny,
  SwitchButton,
  Ticket,
  Tickets,
  UserFilled,
} from "@element-plus/icons";
import { ElMessageBox } from "element-plus";
import { UseDark } from "@vueuse/components";

export default {
  name: "AppFrontend",
  components: {
    SetUp,
    Avatar,
    Discount,
    Moon,
    Sunny,
    UseDark,
    SwitchButton,
    UserFilled,
    Setting,
    Tickets,
    Ticket,
    HomeFilled,
    KaPrint,
    KaChartPie,
    KaUsers,
    MenuLink,
    Logo,
  },
  mixins: [Command],
  provide() {
    return {
      openCommentDrawer: (record) => {
        return this.$openCommentDrawer(record);
      },
      openFileDrawer: (file) => {
        return this.$openFileDrawer(file);
      },
    };
  },
  expose: ["openCommentDrawer", "openFileDrawer", "showPrinterSetup", "logout"],
  data() {
    return {
      badges: {},
      defaultActive: null,
    };
  },
  head: {
    titleTemplate: "%s - KA Pass Backend",
    description: `Mit dieser Website können stja-Mitarbeitende Karslruhe Pass und Kinderpass ausstellen und verwalten.`,
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn.value;
    },
    notLoggedIn() {
      return !this.$auth.loggedIn.value;
    },
    authError() {
      return this.$auth.error.value;
    },
    env() {
      return RAILS_ENV;
    },
    envNotProduction() {
      const { env } = this;
      return env !== "production";
    },
    printerQueueLength() {
      return PrinterQueueLength.value;
    },
    hasSdika() {
      return hasSdika.value;
    },
  },
  watch: {
    $route: "syncMenuState",
  },
  mounted() {
    if (this.authError) {
      console.log("authError", this.authError);
      return ElMessageBox({
        title: "Fehler",
        message:
          "Es ist ein Fehler beim Authentifizieren aufgetreten – sie wurden ausgeloggt" +
          this.authError,
      })
        .catch(() => false)
        .finally(() => {
          return this.$router.replace({ name: "login" });
        });
    }
    this.syncMenuState();
  },
  methods: {
    showPrinterQueue() {
      return showPrinterQueue(this);
    },
    showPrinterSetup() {
      return showPrinterSetup(null, this);
    },
    syncMenuState() {
      const matched = this.$route.matched.reverse();
      for (let i = 0; i < matched.length; i++) {
        if (matched[i].meta.menu) {
          this.defaultActive = matched[i].meta.menu;
          return;
        }
      }
    },
    refreshBadges() {},
    logout() {
      this.$auth.logout().finally(() => location.reload());
    },
  },
};
</script>

<style lang="module.css" module>
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: rgb(var(--header-bg-rgb) / 0.8);
  z-index: 20;
}
.menu {
  width: 50vw;
  justify-content: flex-end;
}
.optionalMenuLabel {
  position: absolute;
  left: -9999em;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
:global(.el-menu--popup-container) .optionalMenuLabel {
  position: static;
  width: auto;
  height: auto;
  overflow: initial;
}
.printerQueue {
  min-width: 100px;
}
.envInfo {
  margin-left: 1rem;
  align-self: center;
}

.main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 20px;
}

.main :global(.Content.Content.Content) {
  min-height: auto;
  padding-top: 80px;
  overflow: visible;
}
</style>
