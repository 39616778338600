import { URLS } from "~/src/util/rails.js.erb";
import { defaultOverlayModal } from "~/src/overlays/base";
import { computed, reactive, watch } from "vue";
import { useOnceInAllTabs } from "~/src/hooks/useOnceInAllTabs";
import { useBroadcastChannel, useOnline } from "@vueuse/core";

const BASE_URI = URLS.printer_server;
const LOCAL_STORAGE_KEY = "printer/type2Config/v3";
const LOCAL_QUEUE_STORAGE_KEY = "printer/queue/v1";

export const TYPE_A4 = "a4";
export const TYPE_PASSPORT = "passport";
export const TYPE_VOUCHER = "voucher";
export const TYPE_HUMAN = {
  [TYPE_A4]: "A4",
  [TYPE_PASSPORT]: "Pass",
  [TYPE_VOUCHER]: "Gutschein",
};

export const STATUS_QUEUED = "_queued";
export const STATUS_SENDING = "_sending";
export const STATUS_SEND_ERROR = "_send_error";
export const STATUS_LOOKUP_ERROR = "_lookup_error";

export const STATUS_PENDING = "pending";
export const STATUS_HOLD = "hold";
export const STATUS_PROCESSING = "processing";
export const STATUS_STOPPED = "stopped";
export const STATUS_CANCELED = "canceled";
export const STATUS_ABORTED = "aborted";
export const STATUS_COMPLETED = "completed";

export const STATUS_HUMAN = {
  [STATUS_QUEUED]: "Eingereiht",
  [STATUS_SENDING]: "Wird gesendet",
  [STATUS_SEND_ERROR]: "Fehler beim Senden",
  [STATUS_LOOKUP_ERROR]: "Netzwerkfehler",
  [STATUS_PENDING]: "Wartet",
  [STATUS_HOLD]: "Halten",
  [STATUS_PROCESSING]: "Wird gedruckt",
  [STATUS_STOPPED]: "Stopped",
  [STATUS_CANCELED]: "Storniert",
  [STATUS_ABORTED]: "Abgebrochen",
  [STATUS_COMPLETED]: "Fertig",
};

const RUNNING_STATUSES = [
  STATUS_SENDING,
  STATUS_PENDING,
  STATUS_HOLD,
  STATUS_PROCESSING,
  STATUS_STOPPED,
  STATUS_LOOKUP_ERROR,
];

function load() {
  const d = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (d && typeof d === "object") {
    return d;
  } else {
    return {};
  }
}

function save(type2Config) {
  if (type2Config && typeof type2Config === "object") {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(type2Config));
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
}

let type2Config = load();

const State = reactive({
  uploads: [],
  queue: [],
});

export const PrinterQueueLength = computed(
  () => State.queue.filter((j) => RUNNING_STATUSES.includes(j.status)).length,
);

export const PrinterQueue = computed(() => State.queue);

// noinspection JSUnusedLocalSymbols
function fakePrintOne(job) {
  job.status = STATUS_SENDING;
  console.log("fakePrintOne", job);
  console.log(
    `send ${job.blob.type} (${job.blob.size} bytes, copies: ${job.copies}) to ${job.config.name} (${job.config.paperName}, ${job.config.url})`,
  );
  setTimeout(function () {
    jobError(job, new Error("Fake!"));
  }, 4000);
}

function printOne(job) {
  const body = new FormData();
  body.append("file", job.blob, job.name || "file.pdf");
  body.append("printer", job.config.name);
  body.append("copies", "" + job.copies);
  body.append("paperName", job.config.paperName);
  job.status = STATUS_SENDING;
  console.log(
    `send ${job.name || "file.pdf"} ${job.blob.type} (${job.blob.size} bytes, copies: ${job.copies}) to ${job.config.name} (${job.config.paperName}, ${job.config.url})`,
    job,
  );
  fetch(job.config.url, {
    contentType: "multipart/form-data",
    method: "POST",
    body: body,
  })
    .then((response) => response.json())
    .then((response) => {
      jobResponse(job, response);
    })
    .catch((e) => {
      console.error(e);
      jobError(job, e);
    });
}

function addJobToQueue(job, onlyLocal = false) {
  const index = State.queue.findIndex((j) => j.uuid === job.uuid);
  if (index > -1) {
    console.log("job already in queue", job);
    return;
  }
  State.queue.unshift(job);
  State.queue.sort(compareJobs);
  persistState();
  if (!onlyLocal && !isClosed.value) {
    post({
      action: ACTION_ADD,
      job: serializeJob(job),
    });
  }
}

function removeJobFromQueue(job, onlyLocal = false) {
  const index = State.queue.findIndex((j) => j.uuid === job.uuid);
  if (index > -1) {
    console.log("remove job from queue", job);
    State.queue.splice(index, 1);
    persistState();
  } else {
    console.log("job not found in queue", job);
  }
  if (!onlyLocal && !isClosed.value) {
    post({
      action: ACTION_REMOVE,
      job: serializeJob(job),
    });
  }
}

function serializeJob(job) {
  return JSON.parse(JSON.stringify(job));
}

function jobResponse(job, response) {
  // so blob can be garbage collected
  delete job.blob;
  if (!response.success) {
    job.status = STATUS_SEND_ERROR;
  } else if (response.jobUrl) {
    job.url = response.jobUrl;
    addJobToQueue(job);
  }
  job.resolve({
    ...response,
    job,
  });
  if (!isClosed.value) {
    post({
      action: ACTION_ADD,
      job: serializeJob(job),
    });
  }
  maybePrintNextJob();
}

function jobError(job, e) {
  console.log("jobError", job, e);
  jobResponse(job, {
    success: false,
    exception: e,
    error: `${e}`,
  });
}

function maybePrintNextJob() {
  const job = State.uploads.shift();
  if (job) {
    printOne(job);
    // fakePrintOne(job);
  }
}

function addJobToUploads(job) {
  State.uploads.push(job);
  maybePrintNextJob();
}

export function web2Print(type, blob, name, copies = 1, parent = null) {
  const job = {
    type,
    blob,
    name,
    copies,
    config: type2Config[type] ? { ...type2Config[type] } : {},
    uuid: self.crypto.randomUUID(),
    status: STATUS_QUEUED,
    printerStatusMessage: null,
    progress: 0,
    stamp: Date.now(),
    done: null,
  };
  return new Promise((resolve) => {
    job.resolve = resolve;
    if (!type2Config[type]) {
      showPrinterSetup(
        `${TYPE_HUMAN[type]} Drucker auswählen zum Fortfahren`,
        parent,
      ).then(() => {
        if (!type2Config[type]) {
          jobError(job, new Error("Kein Drucker ausgewählt"));
        } else {
          addJobToUploads(job);
        }
      });
    } else {
      addJobToUploads(job);
    }
  });
}

export function showPrinterSetup(reason = null, parent = null) {
  return defaultOverlayModal(
    import("~/src/components/common/utils/PrinterSetup"),
    {
      reason: reason,
      base: BASE_URI,
      type2Config: type2Config,
    },
    parent,
  ).then((modalResult) => {
    if (modalResult) {
      type2Config = modalResult;
      save(type2Config);
      return true;
    }
    return false;
  });
}

export function showPrinterQueue(parent = null) {
  return defaultOverlayModal(
    import("~/src/components/common/utils/PrinterQueue"),
    {},
    parent,
  ).then((modalResult) => {
    return !!modalResult;
  });
}
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
let refreshInterval;

function compareJobs(a, b) {
  return a.stamp === b.stamp ? 0 : a.stamp < b.stamp ? 1 : -1;
}

function persistState() {
  if (hasLock.value && localStorage) {
    localStorage.setItem(LOCAL_QUEUE_STORAGE_KEY, JSON.stringify(State.queue));
  }
}

function loadState() {
  if (!hasLock.value || !localStorage) {
    return;
  }
  try {
    const raw = localStorage.getItem(LOCAL_QUEUE_STORAGE_KEY);
    const persisted = !!raw && JSON.parse(raw);
    if (!Array.isArray(persisted)) {
      if (raw) {
        console.error("persisted state is not an array", raw);
      } else {
        console.log("no persisted state");
      }
      return;
    }
    const queue = [...State.queue];
    const ids = new Set();
    queue.forEach((job) => {
      ids.add(job.uuid);
    });
    persisted.forEach((job) => {
      if (!ids.has(job.uuid)) {
        queue.push(job);
      }
    });
    queue.sort(compareJobs);
    console.log("loadState", queue);
    State.queue = queue;
    if (!isClosed.value) {
      console.log("post");
      post({
        action: ACTION_LOAD,
        jobs: queue.map(serializeJob),
      });
    }
  } catch {}
}

async function refreshQueue() {
  const queueCopy = [...State.queue];
  for (let i = 0; i < queueCopy.length; i++) {
    // bail early when we should not refresh queue anymore
    if (!shouldRefreshQueue.value) {
      return;
    }
    const job = queueCopy[i];
    if (job.url && RUNNING_STATUSES.includes(job.status)) {
      try {
        const data = await (
          await fetch(job.url, {
            headers: {
              Accept: "application/json",
            },
            method: "GET",
          })
        ).json();
        job.status = data.status || STATUS_SEND_ERROR;
        job.progress =
          data.status === STATUS_COMPLETED ? 100 : data.progress || 0;
        job.printerStatusMessage = data.printerStatusMessage || null;
      } catch (e) {
        console.error(e);
        job.status = STATUS_LOOKUP_ERROR;
      }
      persistState();
      if (!isClosed.value) {
        post({
          action: ACTION_UPDATE,
          job: serializeJob(job),
        });
      }
      await timeout(job.status === STATUS_LOOKUP_ERROR ? 500 : 50);
    }
  }
  if (shouldRefreshQueue.value) {
    refreshInterval = setTimeout(refreshQueue, 2000);
  }
}

function cancelQueueRefresher() {
  if (refreshInterval) {
    clearTimeout(refreshInterval);
    refreshInterval = undefined;
  }
}
function installQueueRefresher() {
  cancelQueueRefresher();
  if (shouldRefreshQueue.value) {
    refreshInterval = setTimeout(refreshQueue, 1000);
  }
}
const {
  isSupported: broadcastSupported,
  isClosed,
  post,
  data,
  close,
} = useBroadcastChannel({
  name: "web2print:queue",
});
const { isSupported: lockSupported, hasLock } = useOnceInAllTabs({
  name: "web2print:refreshQueue",
});
const online = useOnline();
const shouldRefreshQueue = computed(() => {
  const o = online.value;
  const ls = lockSupported.value;
  const lh = hasLock.value;
  console.log("shouldRefreshQueue", { o, ls, lh });
  return o && ((ls && lh) || !ls);
});
if (!lockSupported.value) {
  // close broadcast channel when locks are not supported since we do not use the channel in this case
  if (broadcastSupported.value) {
    close();
  }
  console.warn(
    "Your browser is old - we will refresh the printer queue in every tab",
  );
}
watch(
  shouldRefreshQueue,
  () => {
    if (hasLock.value) {
      console.log("I am the one tab that refreshes the printer queue");
    }
    if (shouldRefreshQueue.value) {
      console.log("should refresh queue");
      loadState();
      installQueueRefresher();
    } else {
      console.log("should not refresh queue");
    }
  },
  {
    immediate: true,
  },
);

const ACTION_ADD = "add";
const ACTION_REMOVE = "remove";
const ACTION_UPDATE = "update";
const ACTION_LOAD = "load";

watch(data, () => {
  if (data.value) {
    console.dir(
      "got new web2print job action",
      JSON.parse(JSON.stringify(data.value)),
    );
    switch (data.value.action) {
      case ACTION_ADD:
        addJobToQueue(data.value.job, true);
        break;
      case ACTION_REMOVE:
        removeJobFromQueue(data.value.job, true);
        break;
      case ACTION_UPDATE:
        for (let i = 0; i < State.queue.length; i++) {
          if (State.queue[i].uuid === data.value.job.uuid) {
            State.queue[i].status = data.value.job.status;
            State.queue[i].progress = data.value.job.progress;
            persistState();
            return;
          }
        }
        addJobToQueue(data.value.job, true);
        break;
      case ACTION_LOAD:
        for (let i = 0; i < data.value.jobs.length; i++) {
          addJobToQueue(data.value.jobs[i], true);
        }
        break;
    }
  }
});

// purge old jobs (older than 10 Minutes)
setInterval(() => {
  const cutoff = Date.now() - 1000 * 60 * 10; // 10 Minutes in the past
  const purged = State.queue.filter((job) => job.stamp > cutoff);
  if (purged.length !== State.queue.length) {
    State.queue = purged;
    persistState();
  }
}, 10000);

// mark this module as not HMR-able
if (import.meta && import.meta.webpackHot) {
  import.meta.webpackHot.decline();
}
