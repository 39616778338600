import { ApolloClient, ApolloLink } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import { onAuthChange } from "~/src/util/auth";
import { cache } from "~/src/graphql/cache";
import { httpLink, csrfLink } from "~/src/graphql/links";

let operationsWaitingForAuth = [];
let hasAuth = false;

onAuthChange(function (auth) {
  if (auth && auth.loggedIn) {
    hasAuth = true;
    if (operationsWaitingForAuth && operationsWaitingForAuth.length > 0) {
      const operations = [...operationsWaitingForAuth];
      operationsWaitingForAuth = [];
      console.log("Now have auth, waiting operations: ", operations);
      for (let i = 0; i < operations.length; i++) {
        const [operation, forward] = operations[i];
        console.log("now execute ", operation, forward(operation));
      }
    }
  } else {
    hasAuth = false;
  }
});
const waitForAuthLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });
  const ctx = operation.getContext();
  if (ctx.needsAuth && !hasAuth) {
    console.log("queue GraphQL operation because it needs auth", operation);
    operationsWaitingForAuth.push([operation, forward]);
  } else {
    return forward(operation);
  }
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: waitForAuthLink.concat(csrfLink.concat(httpLink)),
  cache,
  // connectToDevTools: true,
  // devtools: {
  //   enabled: true,
  // },
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
