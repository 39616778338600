import { createRouter, createWebHistory } from "vue-router";
import { onAuthChange, ReactiveAuth } from "~/src/util/auth";
import { altPressed } from "~/src/hooks/UseMetaKeys";

// authentication guard
// this will wait for the vuex store to be authenticated before any routing can happen

let isReady = ReactiveAuth.inited.value;
const pendingNavigation = {
  from: null,
  to: null,
  next: null,
};

if (!isReady) {
  onAuthChange(function () {
    if (ReactiveAuth.inited.value) {
      isReady = true;
      if (pendingNavigation.next) {
        authGuard(
          pendingNavigation.to,
          pendingNavigation.from,
          pendingNavigation.next,
        );
      }
    }
  });
}

function authGuard(to, from, next) {
  if (to.name !== "login" && ReactiveAuth.error.value) {
    next({ name: "login" });
    return;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (ReactiveAuth.loggedIn.value && !ReactiveAuth.needs2FA.value) {
      next();
      return;
    }
    next({ name: "login" });
  } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (!ReactiveAuth.loggedIn.value) {
      next();
      return;
    }
    next(false);
  } else {
    next();
  }
}

export function getRouter(routes, base) {
  const router = createRouter({
    history: createWebHistory(base),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      return { left: 0, top: 0 };
    },
    routes,
  });
  router.beforeEach((to, from, next) => {
    if (isReady) {
      authGuard(to, from, next);
    } else {
      if (pendingNavigation.next) {
        next(false);
      } else {
        pendingNavigation.to = to;
        pendingNavigation.from = from;
        pendingNavigation.next = next;
      }
    }
  });

  const realInstall = router.install;
  router.install = (app, options) => {
    app.config.globalProperties.$jumpTo = (route) => {
      if (altPressed.value) {
        const resolvedRoute = router.resolve(route);
        window.open(resolvedRoute.href, "_blank", "noopener");
        return Promise.resolve(undefined);
      } else {
        return router.push(route);
      }
    };
    return realInstall.apply(router, [app, options]);
  };
  return router;
}
