import {
  defaultWindow,
  tryOnMounted,
  tryOnScopeDispose,
  useSupported,
} from "@vueuse/core";
import { ref } from "vue";

export function useOnceInAllTabs(options) {
  const { name, doWork, cleanup, window = defaultWindow } = options;
  const isSupported = useSupported(() => window && window.navigator.locks);
  const hasLock = ref(false);

  if (isSupported.value) {
    const abortController = new AbortController();
    let releaseLock = undefined;
    const close = () => {
      if (releaseLock) {
        releaseLock(undefined);
        releaseLock = undefined;
        if (cleanup) {
          cleanup();
        }
      }
      hasLock.value = false;
    };
    tryOnMounted(() => {
      navigator.locks.request(name, { signal: abortController.signal }, () => {
        hasLock.value = true;
        try {
          if (doWork) {
            doWork();
          }
        } catch (error) {
          hasLock.value = false;
          throw error;
        }
        return new Promise((resolve) => {
          releaseLock = resolve;
        });
      });
    });

    tryOnScopeDispose(() => {
      abortController.abort();
      close();
    });
  }

  return { isSupported, hasLock };
}
