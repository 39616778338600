import "~/src/util/setup-nonce";
import "es6-promise/auto"; // the Promise polyfill needs to be included unconditionally
import { polyfilled } from "~/src/util/polyfills";
import { configureVue } from "~/src/util/setup-vue";
import App from "~/src/Frontend";
import { getRouter } from "~/src/router";
import { getRoutes } from "~/src/router/frontend-routes";
import { apolloProvider } from "~/src/graphql/graphql";
import { AuthPlugin, init as authInit, ReactiveAuth } from "~/src/util/auth";
import { createApp, h, defineComponent } from "vue";
import { installCommentDrawer } from "~/src/overlays/comments";
import { installFileDrawer } from "~/src/overlays/files";
import { useDark } from "@vueuse/core";

ReactiveAuth.setRequiredRole({ role: "employee" });
authInit();

document.addEventListener("DOMContentLoaded", () => {
  polyfilled(function () {
    const router = getRouter(getRoutes());
    const app = configureVue(
      createApp(
        defineComponent({
          setup() {
            useDark();
            return () => h(App);
          },
        }),
      ),
    );
    installCommentDrawer(app);
    installFileDrawer(app);
    app.use(AuthPlugin);
    app.use(apolloProvider);
    app.use(router);
    router.app = app;
    router.isReady().then(() => {
      app.mount("#app");
    });
  });
});
