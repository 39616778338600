import {
  DOCUMENTS,
  KA_PASS_BI_GU,
  KI_PASS_BI_GU,
  REASONS_DEFAULT_MONTHS,
} from "~/src/data/passport-reasons";
import { add, endOfMonth } from "date-fns";
import { toDate } from "~/src/util/date";

export const APPLICATION_STATUS_HUMAN = {
  OPEN: "Offen",
  ACCEPTED: "Angenommen",
  REJECTED: "Abgelehnt",
};

export const WHAT_CODES = {
  KA_PASS: "Karlsruher Pass",
  KI_PASS: "Karlsruher Kinderpass",
};
export const WHAT_CODES_PLURAL = {
  KA_PASS: "Karlsruher Pässe",
  KI_PASS: "Karlsruher Kinderpässe",
};

export const BI_GU = "Bildungsgutschein";
export const BI_GU_PLURAL = "Bildungsgutscheine";

export const REASONS = DOCUMENTS;

export const FINEPRINT = `
Hiermit bestätige ich die Richtigkeit und Vollständigkeit meiner Angaben und versichere, dass ich/wir die o.g. Sozialleistungen erhalte/erhalten bzw. alle für die Prüfung der Anspruchsvoraussetzungen für den Karlsruher Pass/Karlsruher Kinderpass relevanten Angaben gemacht zu haben. Mir ist bekannt, dass vorsätzlich unrichtige oder unvollständige Angaben mit dem Ziel, sich einen rechtswidrigen Vermögensvorteil zu verschaffen, den Tatbestand des Betrugs erfüllen, dieser ist nach § 263 StGB strafbar. 
Die unten aufgeführten Leistungen habe ich erhalten. Es ist mir bekannt, dass der Karlsruher Pass/Karlsruher Kinderpass nicht übertragbar ist und bei Zuwiderhandlung eingezogen werden kann. Die Datenschutzhinweise habe ich erhalten.
`.trim();

export function sortDocuments(reasons) {
  return [...reasons].sort(function (a, b) {
    if (a === b) {
      return 0;
    }
    const aIndex = REASONS.indexOf(a);
    const bIndex = REASONS.indexOf(b);
    if (aIndex > -1 && bIndex > -1) {
      return aIndex - bIndex;
    } else {
      if (bIndex !== -1) {
        return 1;
      } else if (aIndex !== -1) {
        return -1;
      } else {
        return a < b ? -1 : 1;
      }
    }
  });
}

export function isVoucherDefault(passportType, reason, age) {
  if (passportType === "KI_PASS") {
    return KI_PASS_BI_GU[reason] === 1;
  } else if (passportType === "KA_PASS") {
    return (
      (KA_PASS_BI_GU[reason] === 3 && age >= 60) ||
      (KA_PASS_BI_GU[reason] === 4 && age >= 60)
    );
  }
  console.error(
    "isVoucherDefault: unknown passport type",
    passportType,
    reason,
    age,
  );
  return false;
}

export function isVoucherPossible(passportType, reason, age) {
  if (passportType === "KI_PASS") {
    return (
      KI_PASS_BI_GU[reason] === 1 ||
      KI_PASS_BI_GU[reason] === 2 ||
      KI_PASS_BI_GU[reason] === 5
    );
  } else if (passportType === "KA_PASS") {
    return (
      (KA_PASS_BI_GU[reason] === 3 && age >= 60) ||
      (KA_PASS_BI_GU[reason] === 4 && age >= 60)
    );
  }
  console.error(
    "isVoucherPossible: unknown passport type",
    passportType,
    reason,
    age,
  );
  return false;
}

export function voucherPossibleInTerm(passportType, reason, birthday) {
  if (
    passportType === "KA_PASS" &&
    (KA_PASS_BI_GU[reason] === 3 || KA_PASS_BI_GU[reason] === 4)
  ) {
    const months = REASONS_DEFAULT_MONTHS[reason] || 12;
    const sixtiesBirthday = add(toDate(birthday), { years: 60 });
    const termStart = new Date();
    const termEnd = endOfMonth(add(new Date(), { months: months }));
    if (sixtiesBirthday > termStart && sixtiesBirthday < termEnd) {
      return sixtiesBirthday;
    }
  }
  return false;
}

export function voucherTypeByPassportType(passportType) {
  if (passportType === "KI_PASS") {
    return "KI_GU";
  }
  return "BI_GU";
}
