<script>
import { h } from "vue";
export default {
  name: "Nl2Br",
  props: {
    text: {
      type: String,
      default: "",
    },
    emptyText: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    return () => {
      const { text, emptyText } = props;
      if (!text && emptyText) {
        return h("em", null, emptyText);
      }
      const lines = (text || "").split("\n");
      const linesWithBr = [];
      lines.forEach(function (line, i) {
        linesWithBr.push(line);
        if (i < lines.length - 1) {
          linesWithBr.push(h("br"));
        }
      });
      return h("span", null, linesWithBr);
    };
  },
};
</script>
