import { ReactiveAuth } from "~/src/util/auth";

export default {
  name: "PageWithApollo",
  data() {
    return {
      loading: 0,
    };
  },
};

export function apolloDefaults() {
  return {
    $query: {
      loadingKey: "loading",
      fetchPolicy: "no-cache",
      error(error, vm, key, type, options) {
        console.error("$error", { error, vm, key, type, options });
        if (error instanceof Error) {
          this.$apolloErrorHandle(error);
        }
      },
    },
    $skipAll() {
      if (this.$route && this.$route.meta && this.$route.meta.requiresAuth) {
        return !ReactiveAuth.loggedIn.value;
      }
      return false;
    },
  };
}
