<script>
import { getCode, getLabel } from "~/src/components/common/i18n/country";
import { h, resolveComponent } from "vue";

export default {
  name: "CountryCode",
  props: {
    code: {
      type: String,
      required: true,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const ElTooltip = resolveComponent("el-tooltip");
    return () => {
      const { code, tooltip } = props;
      if (tooltip) {
        const tip =
          code === "Z?"
            ? getLabel(code)
            : code === "Z!"
              ? `Staatsangehörigkeit unbekannt`
              : `Bürger*in von ${getLabel(code)}`;
        return h(
          ElTooltip,
          {
            content: tip,
          },
          {
            default: () => [h("span", null, getCode(code))],
          },
        );
      }
      return h("span", null, getCode(code));
    };
  },
};
</script>
