<script>
import { escapeRegExp } from "lodash/string";
import { h } from "vue";

export default {
  name: "QueryHighlighter",
  props: {
    query: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  setup() {
    return function render() {
      const { query, text } = this;
      const normalizeQuery = (query || "").trim().replace(/\s+/g, " ");
      if (normalizeQuery) {
        const pattern = new RegExp(
          `(${normalizeQuery.split(/\s/).map(escapeRegExp).join("|")})`,
          "gi",
        );
        const words = text.split(pattern);
        return h(
          "span",
          this.$attrs,
          words.map(function (word) {
            if (pattern.test(word)) {
              return h("mark", null, [word]);
            } else {
              return word;
            }
          }),
        );
      } else {
        return h("span", this.$attrs, [text]);
      }
    };
  },
};
</script>
