import { $emit } from "../../util/gogocodeTransfer";
export default {
  name: "Overlay",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vis: this.visible,
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(to) {
        this.vis = to;
      },
    },
    vis(to) {
      $emit(this, "update:visible", to);
    },
  },
  emits: ["update:visible"],
};
