<template>
  <span v-text="age" />
</template>

<script>
import { ReactiveDate, toDate } from "~/src/util/date";
import { differenceInYears } from "date-fns";

export function currentAge(birthday) {
  return differenceInYears(ReactiveDate.currentDay, toDate(birthday));
}

export default {
  name: "PersonCurrentAge",
  props: {
    date: {
      type: [Object, Date, String],
      required: true,
    },
    addParentheses: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    age() {
      const { date, addParentheses } = this;
      const { currentDay } = ReactiveDate;
      if (date) {
        const years = differenceInYears(currentDay, toDate(date));
        const v = years === 1 ? `${years} Jahr alt` : `${years} Jahre alt`;
        if (addParentheses) {
          return `(${v})`;
        }
        return v;
      }
      return "";
    },
  },
};
</script>
