import { ApolloClient } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import { cache } from "~/src/graphql/cache";
import { httpLink, csrfLink } from "~/src/graphql/links";

// Vue.use(VueApollo);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: csrfLink.concat(httpLink),
  cache,
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
