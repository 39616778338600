import Element, { ElMessageBox } from "element-plus";
import locale from "element-plus/es/locale/lang/de";
import "~stylesheets/element-ui.scss";
import "~stylesheets/element-ui-dark.scss";
import "~stylesheets/base-styles.scss";
import "./silence-async-validator";
import "dayjs/locale/de";
import { URLS } from "./rails.js.erb";
import { createHead, VueHeadMixin } from "@unhead/vue";

/*
configureCompat({
  MODE: 3,
  GLOBAL_MOUNT: false,
  RENDER_FUNCTION: false,
});
*/

const head = createHead();

/** @param app App */
/** @type App */
export function configureVue(app) {
  if (!app.appConfigDone) {
    app.use(head);
    app.mixin(VueHeadMixin);
    app.use(Element, { locale });
    app.use({
      install(app) {
        app.config.globalProperties.$urls = URLS;
        app.config.globalProperties.$filters = {
          number2Human(number) {
            return (+number).toLocaleString();
          },
        };
        app.config.globalProperties.$apolloErrorHandle = function (error) {
          ElMessageBox.alert(error, "Fehler");
        };
        app.config.globalProperties.$notReady = function (
          msg = "Diese Funktion ist noch nicht fertig",
        ) {
          ElMessageBox.alert(msg, "Noch nicht fertig");
        };
      },
    });

    app.appConfigDone = true;
  }
  return app;
}
