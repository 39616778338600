<template>
  <time :title="formattedDate" :datetime="isoDate">{{ relativeDate }}</time>
</template>

<script>
import { formatRelative } from "date-fns";
import { de } from "date-fns/locale";
import { ReactiveDate } from "~/src/util/date";

export default {
  name: "RelativeDateTime",
  props: {
    date: {
      type: [String, Date],
      default: "",
    },
  },
  computed: {
    dateObj() {
      const { date } = this;

      if (!date) {
        return null;
      }

      return date instanceof Date ? date : new Date(date);
    },
    isoDate() {
      const { dateObj } = this;
      if (!dateObj) {
        return "";
      }

      return dateObj.toISOString();
    },
    formattedDate() {
      const { dateObj } = this;

      if (!dateObj) {
        return "";
      }

      return dateObj.toLocaleString();
    },
    relativeDate() {
      const { dateObj } = this;

      if (!dateObj) {
        return "";
      }

      return formatRelative(dateObj, ReactiveDate.currentMinute, {
        locale: de,
        weekStartsOn: 1,
      });
    },
  },
};
</script>
