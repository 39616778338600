<script>
import { getCityDistrictName } from "./CityDistrictName";
import { h } from "vue";

export default {
  name: "KarlsruheLocality",
  props: {
    localityAndDistrict: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    return () => {
      const { localityAndDistrict } = props;
      if (
        localityAndDistrict.locality === "Karlsruhe" &&
        localityAndDistrict.cityDistrictNumber &&
        localityAndDistrict.cityDistrictNumber !== "?" &&
        localityAndDistrict.cityDistrictNumber !== "PO"
      ) {
        return h(
          "span",
          null,
          getCityDistrictName(localityAndDistrict.cityDistrictNumber),
        );
      } else {
        return h("span", null, localityAndDistrict.locality);
      }
    };
  },
};
</script>
