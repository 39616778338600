<template>
  <div :class="$style.wrap">
    <div :class="$style.left">
      <slot name="actions" />
    </div>
    <app-pager
      :class="$style.center"
      :has-next-page="hasNextPage"
      :has-previous-page="hasPreviousPage"
      center
      show-always
      @next-page="onNextPage"
      @previous-page="onPreviousPage"
    />
    <el-space :class="$style.right">
      <div :class="$style.results">
        <slot name="results" />
      </div>
      <el-tooltip content="Diese Liste neu vom Server laden">
        <el-button circle :icon="ElIconRefreshRight" @click="onReload" />
      </el-tooltip>
    </el-space>
  </div>
</template>

<script>
import { $emit } from "../../../util/gogocodeTransfer";
import { RefreshRight as ElIconRefreshRight } from "@element-plus/icons";
import { emptyFn } from "~/src/util/empty-fn";
import AppPager from "~/src/components/common/list/AppPager";

export default {
  name: "DataTableFooter",
  components: { AppPager },
  props: {
    reload: {
      type: Function,
      default: emptyFn,
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    hasPreviousPage: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Object,
      default: null,
    },
  },
  emits: ["previous-page", "next-page"],
  computed: {
    ElIconRefreshRight() {
      return ElIconRefreshRight;
    },
  },
  methods: {
    onNextPage() {
      $emit(this, "next-page");
      this.scrollToTableTop();
    },
    onPreviousPage() {
      $emit(this, "previous-page");
      this.scrollToTableTop();
    },
    onReload() {
      const pro = this.reload();
      const chain = pro instanceof Promise ? pro : Promise.resolve(true);
      chain.finally(this.scrollToTableTop);
    },
    scrollToTableTop() {
      const { table } = this;
      if (table) {
        const el = table.$el || table;
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
};
</script>

<style lang="module.css" module>
.wrap {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-areas: "all";
}
.side {
  grid-area: all;
}
.left {
  composes: side;
  justify-self: start;
}
.right {
  composes: side;
  justify-self: end;
}
.center {
  grid-area: all;
  justify-self: center;
}
.results {
  display: inline-block;
  color: #999999;
}
</style>
