<script>
import CITY_DISTRICTS from "../../../data/city_districts.json";
import { h } from "vue";

export function getCityDistrictName(
  number,
  unknown = "unbekannter Stadtteil (%s)",
) {
  if (CITY_DISTRICTS[number]) {
    return `[${number}] ${CITY_DISTRICTS[number]}`;
  } else {
    return unknown.replace("%s", number);
  }
}

export default {
  name: "CityDistrictName",
  props: {
    number: {
      type: String,
      required: true,
    },
    unknown: {
      type: String,
      default: "unbekannter Stadtteil (%s)",
    },
  },
  setup(props) {
    return () => {
      const { number, unknown } = props;
      return h("span", getCityDistrictName(number, unknown));
    };
  },
};
</script>
