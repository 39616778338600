<template>
  <el-tooltip v-if="numKaPass > 0" :content="kaPass" :tabindex="-1">
    <span :class="[$style.group, $style.KA_PASS]"
      ><el-icon><KaKarlsruhe /></el-icon>×{{ numKaPass }}</span
    >
  </el-tooltip>
  <el-tooltip v-if="numKiPass > 0" :content="kiPass" :tabindex="-1">
    <span :class="[$style.group, $style.KI_PASS]"
      ><el-icon><KaChild /></el-icon>×{{ numKiPass }}</span
    >
  </el-tooltip>
  <el-tooltip v-if="numVouchers > 0" :content="vouchers" :tabindex="-1">
    <span :class="[$style.group, $style.vouchers]"
      ><el-icon><KaGraduationCap /></el-icon>×{{ numVouchers }}</span
    >
  </el-tooltip>
</template>

<script>
import {
  BI_GU,
  BI_GU_PLURAL,
  WHAT_CODES,
  WHAT_CODES_PLURAL,
} from "~/src/util/application";
import KaKarlsruhe from "~/src/components/common/icons/KaKarlsruhe.vue";
import KaChild from "~/src/components/common/icons/KaChild.vue";
import KaGraduationCap from "~/src/components/common/icons/KaGraduationCap.vue";

export default {
  name: "ApplicationItemsSummary",
  components: { KaGraduationCap, KaChild, KaKarlsruhe },
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      const { application } = this;
      return application.items || [];
    },
    numKaPass() {
      const { KA_PASS } = this.nums;
      return KA_PASS || 0;
    },
    kaPass() {
      const { numKaPass } = this;
      if (numKaPass === 1) {
        return "1 " + WHAT_CODES["KA_PASS"];
      }
      return `${numKaPass} ${WHAT_CODES_PLURAL["KA_PASS"]}`;
    },
    numKiPass() {
      const { KI_PASS } = this.nums;
      return KI_PASS || 0;
    },
    kiPass() {
      const { numKiPass } = this;
      if (numKiPass === 1) {
        return "1 " + WHAT_CODES["KI_PASS"];
      }
      return `${numKiPass} ${WHAT_CODES_PLURAL["KI_PASS"]}`;
    },
    numVouchers() {
      const { BI_GU } = this.nums;
      return BI_GU || 0;
    },
    vouchers() {
      const { numVouchers } = this;
      if (numVouchers === 1) {
        return "1 " + BI_GU;
      }
      return `${numVouchers} ${BI_GU_PLURAL}`;
    },
    nums() {
      const { items } = this;
      const codeCounts = {};
      let voucherCount = 0;
      items.forEach(function (item) {
        if (!(item.whatCode in codeCounts)) {
          codeCounts[item.whatCode] = 0;
        }
        codeCounts[item.whatCode] += 1;
        if (item.wantsVoucher) {
          voucherCount += 1;
        }
      });
      return {
        ...codeCounts,
        BI_GU: voucherCount,
      };
    },
  },
};
</script>

<style lang="module.css" module>
.group {
  background: #eee;
  border-radius: 6px;
  padding: 0 2px;
}
.KA_PASS {
  background: #c41440;
  color: #fff;
}
.KI_PASS {
  background: #1e89cf;
  color: #fff;
 }
.vouchers {
  background: var(--el-color-info);
  color: var(--el-color-info-light-9);
}
.group + .group {
  margin-left: 6px;
}
</style>
