<template>
  <nl2-br :text="formattedDate" />
</template>

<script>
import Nl2Br from "~/src/components/common/utils/Nl2Br";

export default {
  name: "LocaleDateTime",
  components: { Nl2Br },
  props: {
    date: {
      type: [String, Date],
      default: "",
    },
    twoLines: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedDate() {
      const { date, twoLines } = this;

      if (!date) {
        return "";
      }
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const str =
        date instanceof Date
          ? date.toLocaleString("de", options)
          : new Date(date).toLocaleString("de", options);
      if (twoLines) {
        return str.replace(/,\s*/g, "\n");
      }
      return str;
    },
  },
};
</script>
