<template>
  <el-tag
    :type="
      status === 'REJECTED'
        ? 'danger'
        : status === 'OPEN'
          ? 'warning'
          : 'success'
    "
    :size="size"
  >
    <template v-if="status === 'REJECTED'"> Abgelehnt </template>
    <template v-else-if="status === 'ACCEPTED'"> Angenommen </template>
    <template v-else-if="status === 'OPEN'"> Offen </template>
    <template v-else>
      {{ status }}
    </template>
  </el-tag>
</template>

<script>
export default {
  name: "ApplicationStatus",
  props: {
    status: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "small",
    },
  },
};
</script>
