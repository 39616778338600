import { defineComponent, h, createApp, nextTick } from "vue";
import { configureVue } from "~/src/util/setup-vue";
import { apolloProvider } from "~/src/graphql/public";

function getGlobalProperty(appOrInstance, name) {
  if (appOrInstance) {
    if (name in appOrInstance) {
      return appOrInstance[name];
    }
    if (appOrInstance.config?.globalProperties) {
      return appOrInstance.config.globalProperties[name];
    }
  }
  return undefined;
}

function createOverlayVue(component, props, parent, resolve) {
  const rootEl = document.createElement("div");
  document.body.appendChild(rootEl);
  const modal = defineComponent({
    provide() {
      return {
        openCommentDrawer: (record) => {
          return getGlobalProperty(parent, "$openCommentDrawer")(record);
        },
        openFileDrawer: (file) => {
          return getGlobalProperty(parent, "$openFileDrawer")(file);
        },
      };
    },
    emits: ["close"],
    setup(_props, { emit }) {
      return () => {
        return h(component, {
          ...props,
          onClose(modalResult) {
            emit("close", ...arguments);
            resolve(modalResult);
            nextTick().then(() => {
              app.unmount();
              rootEl.remove();
            });
          },
        });
      };
    },
  });
  const app = configureVue(createApp(modal));
  app.use(apolloProvider);
  const router = getGlobalProperty(parent, "$router");
  if (router) {
    app.use(router);
  } else {
    console.error("no router found");
  }
  app.config.globalProperties.$openCommentDrawer = getGlobalProperty(
    parent,
    "$openCommentDrawer",
  );
  app.config.globalProperties.$openFileDrawer = getGlobalProperty(
    parent,
    "$openFileDrawer",
  );
  app.mount(rootEl);
  return modal;
}

export function defaultOverlayModal(
  component,
  props = {},
  callingComponent = null,
) {
  return new Promise((resolve, reject) => {
    function mount(resolvedComponent) {
      createOverlayVue(resolvedComponent, props, callingComponent, resolve);
    }
    if (component instanceof Promise) {
      component
        .then(function (loadedModule) {
          mount(loadedModule.default);
        })
        .catch(function (e) {
          reject(e);
        });
    } else {
      mount(component);
    }
  });
}
