import { defaultOverlayModal } from "./base";

export function openCommentDrawer(props, callingComponent) {
  return defaultOverlayModal(
    import("~/src/components/comments/CommentDrawer"),
    props,
    callingComponent,
  );
}

export function installCommentDrawer(app) {
  app.config.globalProperties.$openCommentDrawer = function (record) {
    return openCommentDrawer(
      {
        record: record,
      },
      app,
    );
  };
}
