<script>
import { formattedDate, formattedMonth, formattedWeek } from "~/src/util/date";
import { h } from "vue";

export default {
  name: "LocaleDate",
  props: {
    date: {
      type: [String, Date],
      default: "",
    },
    onlyMonth: {
      type: Boolean,
      default: false,
    },
    onlyWeek: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return () => {
      const { date, onlyMonth, onlyWeek } = props;

      if (onlyMonth) {
        return h("span", null, formattedMonth(date));
      }
      if (onlyWeek) {
        return h("span", null, formattedWeek(date));
      }
      return h("span", null, formattedDate(date));
    };
  },
};
</script>
