<template>
  <el-main class="Content">
    <div v-if="$auth.hasRightPassportManagement">
      <list-heading>
        Offene Anträge
        <template #actions>
          <navigation-button
            type="primary"
            size="small"
            :icon="Plus"
            :to="{ name: 'new-application' }"
          >
            Neuer Antrag
          </navigation-button>
          <navigation-button
            type="primary"
            size="small"
            :icon="Tickets"
            plain
            :to="{ name: 'applications' }"
          >
            Alle Anträge
          </navigation-button>
        </template>
      </list-heading>
      <application-list
        hide-status
        :loading="$apollo.queries.openApplications.loading"
        :list="openApplications.nodes"
        :total-count="openApplications.totalCount"
        :page-info="openApplications.pageInfo"
        :has-next-page="openApplications.pageInfo.hasNextPage"
        :has-previous-page="openApplications.pageInfo.hasPreviousPage"
        :go-to-next-page="nextOpenApplicationsPage"
        :go-to-previous-page="previousOpenApplicationsPage"
        :reload="reloadOpenApplications"
      >
        <el-table-column width="50">
          <template #default="{ row }">
            <navigation-button
              :to="{
                name: 'application',
                params: { applicationId: row.id },
              }"
              type="primary"
              plain
              circle
              :icon="KaEye"
            />
          </template>
        </el-table-column>
      </application-list>
    </div>
    <div
      v-if="
        $auth.hasRightPassportRead ||
        $auth.hasRightStatistics ||
        $auth.hasRightEmployeeManagement
      "
    >
      <action-list margin center>
        <navigation-button
          v-if="$auth.hasRightPassportRead"
          :icon="UserFilled"
          :to="{ name: 'families' }"
        >
          Familie/Person suchen
        </navigation-button>
        <navigation-button
          v-if="$auth.hasRightStatistics"
          :icon="DataAnalysis"
          :to="{ name: 'statistics' }"
        >
          Zur Statistik-Auswertung
        </navigation-button>
        <navigation-button
          v-if="$auth.hasRightEmployeeManagement"
          :icon="Setting"
          :to="{ name: 'employees' }"
        >
          Zur Mitarbeitenden-Verwaltung
        </navigation-button>
      </action-list>
    </div>
  </el-main>
</template>

<script>
import ListHeading from "~/src/components/common/list/ListHeading";
import NavigationButton from "~/src/components/common/utils/NavigationButton";
import ApplicationList from "~/src/components/applications/ApplicationList";
import PageWithApollo, {
  apolloDefaults,
} from "~/src/components/mixins/PageWithApollo";
import ReloadablePage from "~/src/components/mixins/ReloadablePage";
import gql from "graphql-tag";
import { ApplicationFragment, PageInfoFragment } from "~/src/graphql/fragments";
import {
  DIRECTION_BACKWARD,
  DIRECTION_FORWARD,
  getEmptyConnection,
  getEmptyPaging,
  getPagingVariables,
} from "~/src/util/paging";
import ActionList from "~/src/components/common/utils/ActionList";
import KaEye from "~/src/components/common/icons/KaEye.vue";
import {
  DataAnalysis,
  Plus,
  Setting,
  Tickets,
  UserFilled,
} from "@element-plus/icons";

export default {
  name: "PageIndex",
  components: { ActionList, ApplicationList, NavigationButton, ListHeading },
  mixins: [PageWithApollo, ReloadablePage],
  data() {
    return {
      openApplications: getEmptyConnection(),
      openApplicationsPaging: getEmptyPaging(),
    };
  },
  head: {
    title: "KA Pass",
    titleTemplate: null,
  },
  computed: {
    Setting() {
      return Setting;
    },
    DataAnalysis() {
      return DataAnalysis;
    },
    UserFilled() {
      return UserFilled;
    },
    Plus() {
      return Plus;
    },
    Tickets() {
      return Tickets;
    },
    KaEye() {
      return KaEye;
    },
  },
  apollo: {
    ...apolloDefaults(),
    openApplications: {
      context: {
        needsAuth: true,
      },
      throttle: 500,
      loadingKey: "loading",
      fetchPolicy: "no-cache",
      skip: (vm) => !vm.$auth.hasRightPassportManagement,
      update: (data) => data.asEmployee.applications,
      query: gql`
        query AsEmployeeGetOpenApplications(
          $first: Int
          $last: Int
          $after: String
          $before: String
        ) {
          asEmployee {
            applications(
              first: $first
              last: $last
              after: $after
              before: $before
              status: OPEN
              sort: [{ column: "createdAt", direction: DESC }]
            ) {
              nodes {
                ...ApplicationFragment
              }
              pageInfo {
                ...PageInfoFragment
              }
              totalCount
            }
          }
        }
        ${ApplicationFragment}
        ${PageInfoFragment}
      `,
      variables() {
        const { openApplicationsPaging } = this;
        return {
          ...getPagingVariables(openApplicationsPaging),
        };
      },
    },
  },
  beforeMount() {
    this.addToReloadMethods(this.reloadOpenApplications);
  },
  methods: {
    reloadOpenApplications() {
      if (this.$auth.hasRightPassportManagement) {
        this.$apollo.queries.openApplications.refetch();
      }
    },
    nextOpenApplicationsPage() {
      this.openApplicationsPaging.direction = DIRECTION_FORWARD;
      this.openApplicationsPaging.cursor =
        this.openApplications.pageInfo.endCursor;
    },
    previousOpenApplicationsPage() {
      this.openApplicationsPaging.direction = DIRECTION_BACKWARD;
      this.openApplicationsPaging.cursor =
        this.openApplications.pageInfo.startCursor;
    },
  },
};
</script>
