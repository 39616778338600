import { createHttpLink } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

// HTTP connection to the API
export const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: "//" + location.host + "/graphql",
  credentials: "same-origin",
});

export const csrfLink = setContext((_, { headers }) => {
  const csrfToken = (document.querySelector("meta[name=csrf-token]") || {})
    .content;
  return {
    headers: {
      ...headers,
      "X-CSRF-Token": csrfToken,
      "X-Env": location.host,
    },
  };
});
