import { defaultOverlayModal } from "./base";

export function openFileDrawer(props, callingComponent) {
  return defaultOverlayModal(
    import("~/src/components/common/uploads/FileDrawer"),
    props,
    callingComponent,
  );
}

export function installFileDrawer(app) {
  app.config.globalProperties.$openFileDrawer = function (file) {
    return openFileDrawer(
      {
        file: file,
      },
      app,
    );
  };
}
