<template>
  <button type="button" :class="$style.wrap" @click="$emit('click', $event)">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38.7 25.8"
      :class="[
        $style.icon,
        $style[size],
        count > 0 ? $style.iconHasComments : null,
      ]"
    >
      <path
        d="M24.5,11.6c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5
      	c-0.3,0.3-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5C23.7,12.1,24.2,11.9,24.5,11.6z M17.4,11.6
      	c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2
      	s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5C16.7,12.1,17.1,11.9,17.4,11.6z M10.3,11.6c0.3-0.3,0.5-0.7,0.5-1.2s-0.2-0.9-0.5-1.2
      	C10,8.8,9.6,8.6,9.1,8.6S8.2,8.8,7.8,9.1c-0.3,0.3-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
      	C9.6,12.1,10,11.9,10.3,11.6z M28.3,0.3v20.2H17.2l-7.1,5.1v-5.1H4V0.3H28.3z"
      />
    </svg>
    <span v-if="count > 0" :class="$style.count">{{ count }}</span>
  </button>
</template>

<script>
export default {
  name: "CommentCount",
  props: {
    count: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "default",
    },
  },
  emits: ["click"],
};
</script>

<style lang="module.scss" module>
@use "~stylesheets/vars";
.wrap {
  position: relative;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}
.icon {
  cursor: pointer;
  fill: #666666;
}
.default {
  width: 38px;
}
.small {
  width: 24px;
}
.iconHasComments {
  fill: #{vars.$stja-purpur};
}
:global(html.dark) .iconHasComments {
  stroke: #fff;
  stroke-opacity: .5;
}
.count {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #{vars.$stja-orange};
  color: #000;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 10px;
}
</style>
