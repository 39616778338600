import { defineComponent, h, resolveComponent } from "vue";

const requiresAuth = {
  requiresAuth: true,
};
const requiresNoAuth = {
  requiresNoAuth: true,
};

const SubView = defineComponent({
  name: "SubView",
  setup() {
    const RouterView = resolveComponent("RouterView");
    return () => h(RouterView);
  },
});

export function getRoutes() {
  return [
    // basic routes
    // ===================================================================================================
    {
      path: "/",
      name: "index",
      component: require("~/src/pages").default,
      meta: {
        ...requiresAuth,
        menu: "index",
      },
    },
    {
      path: "/login",
      name: "login",
      component: require("~/src/pages/user/login.vue").default,
      meta: requiresNoAuth,
    },
    {
      path: "/verify-email",
      name: "verify-email",
      component: require("~/src/pages/user/verify-email.vue").default,
    },
    {
      path: "/impressum",
      name: "imprint",
      component: require("~/src/pages/imprint").default,
    },
    {
      path: "/datenschutz",
      name: "privacy",
      component: require("~/src/pages/privacy").default,
    },

    // logged in
    // ===================================================================================================
    {
      path: "/me",
      name: "profile",
      component: () => import("~/src/pages/user/profile.vue"),
      meta: {
        ...requiresAuth,
        menu: "profile",
      },
    },
    {
      path: "/passport-management",
      component: SubView,
      meta: {
        ...requiresAuth,
        menu: "passport-management-menu",
      },
      children: [
        {
          path: "",
          name: "passport-management",
          redirect: { name: "families" },
        },
        {
          path: "families",
          component: SubView,
          meta: {
            menu: "families",
          },
          children: [
            {
              path: "",
              name: "families",
              meta: requiresAuth,
              component: () =>
                import("~/src/pages/passport-management/families.vue"),
            },
            {
              path: "people",
              name: "people",
              meta: requiresAuth,
              component: () => import("~/src/pages/passport-management/people"),
            },
            {
              path: "new-person",
              name: "new-person",
              meta: requiresAuth,
              props: (route) => ({ familyId: route.query.familyId || null }),
              component: () =>
                import("~/src/pages/passport-management/new-person.vue"),
            },
            {
              path: ":familyId(\\d+)",
              name: "family",
              meta: requiresAuth,
              props: true,
              component: () =>
                import("~/src/pages/passport-management/family.vue"),
            },
            {
              path: ":familyId(\\d+)/person/:personId(\\d+)",
              name: "person",
              meta: requiresAuth,
              props: true,
              redirect: (to) => ({
                name: "family",
                params: { familyId: to.params.familyId },
                query: { personId: to.params.personId, tab: "passports" },
              }),
            },
            {
              path: ":familyId(\\d+)/person/:personId(\\d+)/edit",
              name: "edit-person",
              meta: requiresAuth,
              props: true,
              component: () =>
                import("~/src/pages/passport-management/edit-person.vue"),
            },
          ],
        },
        {
          path: "applications",
          component: SubView,
          meta: {
            menu: "applications",
          },
          children: [
            {
              path: "",
              name: "applications",
              meta: requiresAuth,
              component: () =>
                import("~/src/pages/passport-management/applications.vue"),
            },
            {
              path: "new",
              name: "new-application",
              meta: requiresAuth,
              component: () =>
                import("~/src/pages/passport-management/new-application.vue"),
            },
            {
              path: ":applicationId(\\d+)",
              name: "application",
              meta: requiresAuth,
              props: true,
              component: () =>
                import("~/src/pages/passport-management/application.vue"),
            },
            {
              path: ":applicationId(\\d+)/accept",
              name: "accept-application",
              meta: requiresAuth,
              props: true,
              component: () =>
                import(
                  "~/src/pages/passport-management/accept-application.vue"
                ),
            },
          ],
        },
        {
          path: "passports",
          component: SubView,
          meta: {
            menu: "passports",
          },
          children: [
            {
              path: "",
              name: "passports",
              meta: requiresAuth,
              component: () =>
                import("~/src/pages/passport-management/passports.vue"),
            },
            {
              path: ":passportId(\\d+)",
              name: "passport",
              meta: requiresAuth,
              props: true,
              component: () =>
                import("~/src/pages/passport-management/passport.vue"),
            },
          ],
        },
        {
          path: "digital-passports",
          component: SubView,
          meta: {
            menu: "digital-passports",
          },
          children: [
            {
              path: "",
              name: "digital-passports",
              meta: requiresAuth,
              component: () =>
                import("~/src/pages/passport-management/digital-passports.vue"),
            },
          ],
        },
      ],
    },

    // employees routes
    // ===================================================================================================
    {
      path: "/perks",
      component: SubView,
      meta: {
        ...requiresAuth,
        menu: "perks",
      },
      children: [
        {
          path: "",
          name: "perks",
          component: () => import("~/src/pages/perks"),
        },
        {
          path: "new",
          name: "new-perk",
          component: () => import("~/src/pages/perks/new.vue"),
        },
        {
          path: ":perkId(\\d+)",
          name: "perk",
          meta: requiresAuth,
          props: true,
          component: () => import("~/src/pages/perks/edit.vue"),
        },
      ],
    },

    // statistics routes
    // ===================================================================================================
    {
      path: "/statistics",
      component: SubView,
      meta: {
        ...requiresAuth,
        menu: "statistics",
      },
      children: [
        {
          path: "",
          name: "statistics",
          component: () => import("~/src/pages/statistics"),
        },
        {
          path: "close/:month(\\d{4}-\\d{2})",
          name: "statisticsCloseMonth",
          props: true,
          component: () => import("~/src/pages/statistics/close-month"),
        },
      ],
    },

    // employees routes
    // ===================================================================================================
    {
      path: "/employees",
      component: SubView,
      meta: {
        ...requiresAuth,
        menu: "employees",
      },
      children: [
        {
          path: "",
          name: "employees",
          component: () => import("~/src/pages/employees"),
        },
        {
          path: "new",
          name: "new-employee",
          component: () => import("~/src/pages/employees/new.vue"),
        },
      ],
    },

    // seldom used user auth routes
    // ===================================================================================================
    {
      path: "/password-reset",
      name: "password-reset",
      component: () => import("~/src/pages/user/password-reset.vue"),
    },
    {
      path: "/set-password",
      name: "set-password",
      component: () => import("~/src/pages/user/set-password.vue"),
    },
    {
      path: "/validate-email",
      name: "validate-email",
      component: () => import("~/src/pages/user/validate-email.vue"),
    },
    {
      path: "/unlock-email",
      name: "unlock-email",
      component: () => import("~/src/pages/user/unlock-email.vue"),

      meta: requiresNoAuth,
    },
    {
      path: "/unlock",
      name: "unlock",
      component: () => import("~/src/pages/user/unlock.vue"),
    },
    {
      path: "/users/invitation/accept",
      name: "accept-invitation",
      component: () => import("~/src/pages/user/accept-invitation.vue"),
    },

    // fallback 404 route
    // ===================================================================================================
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: require("~/src/pages/404.vue").default,
    },
  ];
}
