import { reactive, readonly, watchEffect } from "vue";
import { groupBy } from "lodash/collection";
import { getISOWeek, getISOWeekYear } from "date-fns";

function z(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number.toString();
}

export function getISO8601Date(date) {
  const d = toDate(date);
  return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}`;
}

export function getISO8601DateMonth(date) {
  const d = toDate(date);
  return `${d.getFullYear()}-${z(d.getMonth() + 1)}`;
}

export function iso8601DateToLocale(iso8601) {
  return toDate(iso8601).toLocaleDateString();
}

export function iso8601DateTimeToLocale(iso8601) {
  return toDate(iso8601).toLocaleString();
}
export function toDate(value) {
  if (value instanceof Date) {
    return value;
  }
  return new Date(value);
}

export function getSchoolYear(date) {
  const at = date instanceof Date ? date : new Date(date);
  const month = at.getMonth() + 1; // it's crazily zero-based
  const year = at.getFullYear();
  const sy = month >= 8 ? `${year}/${year + 1}` : `${year - 1}/${year}`;

  return `${sy}`;
}

export function getSchoolHalfYear(date) {
  const at = date instanceof Date ? date : new Date(date);
  const month = at.getMonth() + 1; // it's crazily zero-based
  const year = at.getFullYear();
  const sy = month >= 8 ? `${year}/${year + 1}` : `${year - 1}/${year}`;
  const fs = [8, 9, 10, 11, 12, 1].includes(month)
    ? "1. Halbjahr"
    : "2. Halbjahr";
  return `${sy} ${fs}`;
}

export function groupBySchoolHalfYear(dates) {
  return groupBy(dates, function (d) {
    return getSchoolHalfYear(d);
  });
}

export function groupBySchoolYear(dates) {
  return groupBy(dates, function (d) {
    return getSchoolYear(d);
  });
}

const date = reactive({
  now: new Date(),
  currentMinute: new Date(),
  currentHour: new Date(),
  currentDay: new Date(),
});
setInterval(() => {
  date.now = new Date();
}, 500);
watchEffect(() => {
  const { now, currentMinute } = date;
  if (now.getMinutes() !== currentMinute.getMinutes()) {
    date.currentMinute = new Date(+now);
  }
});
watchEffect(() => {
  const { currentMinute, currentHour } = date;
  if (currentMinute.getHours() !== currentHour.getHours()) {
    date.currentHour = new Date(+currentMinute);
  }
});
watchEffect(() => {
  const { currentHour, currentDay } = date;
  if (currentHour.getDate() !== currentDay.getDate()) {
    date.currentDay = new Date(+currentHour);
  }
});

export const ReactiveDate = readonly(date);

export function formattedDate(date) {
  if (!date) {
    return "";
  }
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return date instanceof Date
    ? date.toLocaleDateString("de", options)
    : new Date(date).toLocaleDateString("de", options);
}

export function formattedMonth(date, short = false) {
  if (!date) {
    return "";
  }
  const d = date instanceof Date ? date : new Date(date);
  return new Intl.DateTimeFormat("de", {
    year: "numeric",
    month: short ? "short" : "long",
  }).format(d);
}

export function formattedWeek(date) {
  if (!date) {
    return "";
  }
  const d = date instanceof Date ? date : new Date(date);

  return `${getISOWeekYear(d)} KW${getISOWeek(d)}`;
}

export function formatDateForBirthday(str) {
  if (!str) {
    return str;
  }
  if (str instanceof Date) {
    return `${z(str.getDate())}.${z(str.getMonth() + 1)}.${str.getFullYear()}`;
  }
  if (str.indexOf(".") > -1) {
    return str;
  }
  const a = str.split("-");
  return `${z(a[2])}.${z(a[1])}.${a[0]}`;
}

// 26.03.2007 -> 2007-03-26
export function parseDateForBirthday(str) {
  if (!str) {
    return str;
  }
  if (str instanceof Date) {
    return str;
  }
  const a = str.split(".");
  return new Date(a[2], a[1] - 1, a[0]);
}
