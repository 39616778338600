<template>
  <el-menu-item :route="to" :index="to.name">
    <slot />
  </el-menu-item>
</template>

<script>
export default {
  name: "MenuLink",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    onlyExact: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["navigated"],
};
</script>
